import Actions from '../../actions/listings'

export const doPush = (props, params) => {
  let searchParams = new URLSearchParams(props.location.search)

  searchParams.delete('title')

  if (params.kinds) {
    searchParams.delete('kind[]')
  }
  if (window.kind != '') {
    searchParams.set('kind[]', window.kind)
  }
  if (window.kinds && window.kinds.length > 0) {
    for (let i = 0, l = window.kinds.length; i < l; i++) {
      searchParams.append('kind[]', window.kinds[i]);
    }
  }

  if (params.target == 'rent') {
    searchParams.delete('buy')
    searchParams.delete('monthly')
    searchParams.set('rent', 'on')
  } else if (params.target == 'buy') {
    searchParams.delete('rent')
    searchParams.delete('monthly')
    searchParams.set('buy', 'on')
  } else if (params.target == 'monthly') {
    searchParams.delete('rent')
    searchParams.delete('buy')
    searchParams.set('monthly', 'on')
  }

  if (window.byUnit) {
    searchParams.set('by_unit', 'on')
  }

  if (params.map) {
    searchParams.set('map', params.map)
  }

  if (params.moveIn) {
    if (params.moveIn == 'clear') {
      searchParams.delete('move_in')
    } else {
      searchParams.set('move_in', params.moveIn.toLocaleDateString('sv-SE'))
    }
  }

  if (params.area) {
    searchParams.delete('train_station_name')
    searchParams.delete('station_distance_max')
    searchParams.delete('line_station_ids[]')
    for (const k of searchParams.keys()) {
      k.startsWith('areas[') && searchParams.delete(k)
    }
    searchParams.delete('city_name')
    searchParams.delete('area[kind]')
    searchParams.delete('area[id]')
    searchParams.delete('area[name]')
    searchParams.delete('area[lat]')
    searchParams.delete('area[lng]')

    if (params.area['id']) {
      searchParams.set('area[kind]', params.area['kind'])
      searchParams.set('area[id]', params.area['id'])
      searchParams.set('area[name]', params.area['name'])
      searchParams.set('area[lat]', params.area['latitude'])
      searchParams.set('area[lng]', params.area['longitude'])
    }
    if (params.area['kind'] == 'city') {
      searchParams.set(`areas[${params.area['key']}]`, 'on')
    }
    if (params.area['kind'] == 'line') {
      searchParams.set('train_station_name', params.area['key'])
      searchParams.set('station_distance_max', 15)
    }
    if (params.area['kind'] == 'station') {
      searchParams.set('train_station_name', `station-${params.area['id']}`)
      searchParams.set('station_distance_max', 15)
    }
  }

  if (params.rent) {
    if (params.rent == 'clear') {
      searchParams.delete('rent_range[min]')
      searchParams.delete('rent_range[max]')
    } else {
      searchParams.set('rent_range[min]', params.rent[0])
      searchParams.set('rent_range[max]', params.rent[1])
    }
  }
  if (params.price) {
    if (params.price == 'clear') {
      searchParams.delete('price_range[min]')
      searchParams.delete('price_range[max]')
    } else {
      searchParams.set('price_range[min]', params.price[0])
      searchParams.set('price_range[max]', params.price[1])
    }
  }
  if (params.bedroom) {
    if (params.bedroom == 'clear') {
      searchParams.delete('bedroom_range[min]')
      searchParams.delete('bedroom_range[max]')
    } else {
      searchParams.set('bedroom_range[min]', params.bedroom[0])
      searchParams.set('bedroom_range[max]', params.bedroom[1])
    }
  }
  if (params.size) {
    if (params.size == 'clear') {
      searchParams.delete('size_min')
      searchParams.delete('size_max')
    } else {
      searchParams.set('size_min', params.size[0])
      searchParams.set('size_max', params.size[1])
    }
  }
  if (params.year) {
    if (params.year == 'clear') {
    searchParams.delete('year_range[min]')
    searchParams.delete('year_range[max]')
    } else {
      searchParams.set('year_range[min]', params.year[0])
      searchParams.set('year_range[max]', params.year[1])
    }
  }

  if (params.kinds && params.kinds.length > 0) {
    for (let i = 0, l = params.kinds.length; i < l; i++) {
      searchParams.append('kind[]', params.kinds[i]);
    }
  }

  Object.keys(window.additionalsMap).forEach((key) => {
    if (params[key]) {
      if (params[key] == 'on') {
        searchParams.set(key, 'on')
      } else {
        searchParams.delete(key)
      }
    }
  })

  if (params.sortOrder) {
    if (params.sortOrder === 'clear') {
      searchParams.delete('sort_order')
    } else {
      searchParams.set('sort_order', params.sortOrder)
    }
  }
  if (params.unit) {
    if (params.unit === 'clear') {
      searchParams.delete('units')
    } else {
      searchParams.set('units', params.unit)
    }
  }

  if (params.bounds) {
    if (params.bounds.nw) {
      searchParams.set('bounds[nw][lat]', params.bounds.nw.lat)
      searchParams.set('bounds[nw][lng]', params.bounds.nw.lng)
    } else {
      searchParams.delete('bounds[nw][lat]')
      searchParams.delete('bounds[nw][lng]')
    }
    if (params.bounds.se) {
      searchParams.set('bounds[se][lat]', params.bounds.se.lat)
      searchParams.set('bounds[se][lng]', params.bounds.se.lng)
    } else {
      searchParams.delete('bounds[se][lat]')
      searchParams.delete('bounds[se][lng]')
    }
  }

  if (params.page) {
    if (params.page === 'clear') {
      searchParams.delete('page')
    } else {
      searchParams.set('page', params.page)
    }
  }

  if (params.areaFor) {
    if (params.areaFor === 'clear') {
      searchParams.delete('area_for')
    } else {
      searchParams.set('area_for', params.areaFor)
    }
  }

  props.history.push({search: searchParams.toString()})

  if (window.abortController && props.loading) {
    window.abortController.abort()
  }

  let fitBounds = true
  if (params.page == undefined || params.page < 0) {
    fitBounds = false
  }
  if (window.isMobile) {
    fitBounds = false
  }

  if (params.coordinates instanceof Array) {
    window.params.coordinates = params.coordinates
  }
  props.dispatch(Actions.search(searchParams, fitBounds, window.params.coordinates))
}
