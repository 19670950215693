import update from 'immutability-helper'

const initialState = {
  loading: window.loading,
  map: window.map,
  properties: [],
  page: {},
  buyProperties: [],
  buyPage: {},
  monthlyProperties: [],
  monthlyPage: {},
  target: window.params.target,
  moveIn: window.params.moveIn,
  locations: window.params.locations,
  ranges: window.params.ranges,
  kinds: window.params.kinds,
  additionals: window.params.additionals,
  sorts: window.params.sorts,
  selectable: true,
  coordinates: []
}

export default function(state = initialState, action) {
  switch (action.type) {
  case 'LOADING':
    return { ...state, loading: action.payload.loading }

  case 'BEGIN_SEARCH':
    const begin_search_properties = action.payload.empty ? [] : state.properties
    return { ...state, properties: begin_search_properties }

  case 'SEARCH_RESULTS':
    switch (action.payload.target) {
    case 'buy':
      return { ...state, buyProperties: action.payload.properties }
    case 'monthly':
      return { ...state, monthlyProperties: action.payload.properties }
    default:
      return { ...state, properties: action.payload.properties }
    }

  case 'CHANGE_PAGE':
    switch (action.payload.target) {
    case 'buy':
      return { ...state, buyPage: action.payload.page }
    case 'monthly':
      return { ...state, monthlyPage: action.payload.page }
    default:
      return { ...state, page: action.payload.page }
    }

  case 'CHANGE_TARGET':
    return { ...state, target: action.payload.target }

  case 'CHANGE_MOVE_IN':
    return { ...state, moveIn: action.payload.moveIn }

  case 'SEARCH_AREA':
    return { ...state, locations: update(state.locations, {isLoading: {$set: true}}) }

  case 'SELECT_AREAS':
    return { ...state, locations: update(state.locations, {isLoading: {$set: false}, suggestions: {$set: action.payload.suggestions}}) }

  case 'CHANGE_AREA':
    const area = action.payload.area
    return { ...state, locations: update(state.locations, {area: {$set: area}}) }

  case 'RESET_LOCATION':
    return { ...state, locations: update(state.locations, {area: {$set: {kind: '', name: '', id: ''}}, title: {$set: ''}}) }

  case 'CHANGE_RANGE':
    return { ...state, ranges: update(state.ranges, {$merge: action.payload.ranges}) }

  case 'CHANGE_KINDS':
    return { ...state, kinds: action.payload.kinds }

  case 'CHANGE_ADDITIONAL':
    return { ...state, additionals: update(state.additionals, {$merge: action.payload.additionals}) }

  case 'CHANGE_SORT_UNIT':
    return { ...state, sorts: update(state.sorts, {$merge: action.payload.sorts}) }

  case 'CHANGE_AREA_FOR':
    return { ...state, sorts: update(state.sorts, {$merge: action.payload.sorts}) }

  case 'CHANGE_MAP':
    return { ...state, map: update(state.map, {$merge: action.payload.map}) }

  case 'SET_COORDINATES':
    return { ...state, coordinates: action.payload.coordinates }

  default:
    return state
  }
}
