import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import Actions from '../../actions/listings'

const L = window.locales

class Target extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {open: nextProps.open}
    }
    return null
  }

  close() {
    this.props.onCloseClick()
  }

  handleTargetChange(target) {
    this.props.dispatch(Actions.changeTarget(target))
    this.props.dispatch(Actions.changeSortUnit(null, this.props.sorts.units))
    this.props.dispatch(Actions.changeKinds([]))
    this.props.dispatch(Actions.changeMoveInOut(null, null))
    let additionals = {}
    if (target == 'buy') {
      additionals = {no_key_money: 'off', no_deposit: 'off', free_rent: 'off', bicycle: 'off', higher_than_ground_floor: 'off', furnished: 'off', available_immediately: 'off', no_japanese: 'off'}
    }
    if (target == 'monthly') {
      additionals = {pet: 'off', no_key_money: 'off', no_deposit: 'off', free_rent: 'off', parking: 'off', bicycle: 'off', autolock: 'off', higher_than_ground_floor: 'off', furnished: 'off', available_immediately: 'off', no_japanese: 'off'}
    }
    Object.keys(additionals).forEach((key) => {
      this.props.dispatch(Actions.changeAdditional({[key]: additionals[key]}))
    })
    this.props.push(Object.assign({}, {target: target}, {moveIn: 'clear'}, {kinds: []}, additionals, {page: 'clear'}, {sortOrder: 'clear'}, {unit: this.props.sorts.units}, {areaFor: 'clear'}))
    this.props.onCloseClick()
  }

  render() {
    let target = null
    if (this.state.open) {
      target = (
        <Modal show={this.state.open} onHide={this.close.bind(this)} animation={false} className="listings_modal">
          <Modal.Header closeButton>
            <Modal.Title>{L.search_for}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-no-footer">
            <div className="sidebar_wrapper">
              <ul>
                <li className="form-check pt-3 px-3 pb-0">
                  <label>
                    <input type="radio" className="form-check-input mark-radio" id="check-rent" name="target" checked={this.props.target == 'rent' || this.props.target == undefined} onChange={this.handleTargetChange.bind(this, 'rent')} />
                    <span className="mark"></span>
                    <span className="text">{L.rent_properties}<span className="d-block" style={{marginLeft: '1.8rem', color: '#999', fontSize: '0.9rem', fontWeight: '400'}}>{L.long_term_rentals}</span></span>
                  </label>
                </li>
                <li className="form-check pt-3 px-3 pb-0">
                  <label>
                    <input type="radio" className="form-check-input mark-radio" id="check-buy" name="target" checked={this.props.target == 'buy'} onChange={this.handleTargetChange.bind(this, 'buy')} />
                    <span className="mark"></span>
                    <span className="text">{L.buy_properties}<span className="d-block" style={{marginLeft: '1.8rem', color: '#999', fontSize: '0.9rem', fontWeight: '400'}}>{L.properties_for_sale}</span></span>
                  </label>
                </li>
                <li className="form-check pt-3 px-3 pb-0">
                  <label>
                    <input type="radio" className="form-check-input mark-radio" id="check-monthly" name="target" checked={this.props.target == 'monthly'} onChange={this.handleTargetChange.bind(this, 'monthly')} />
                    <span className="mark"></span>
                    <span className="text">{L.monthly_apartments}<span className="d-block" style={{marginLeft: '1.8rem', color: '#999', fontSize: '0.9rem', fontWeight: '400'}}>{L.furnished_short_term_rentals}</span></span>
                  </label>
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      )
    }
    return target
  }
}

Target.propTypes = {
  target: PropTypes.string,
  additionals: PropTypes.object.isRequired,
  sorts: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired
}

export default Target
